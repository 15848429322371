import React, { useEffect, useState } from 'react'
import Menu from '../../components/Menu/Menu'
import { i18n } from '../../utils/translates/i18n'
import { getPercentageIncomes, savePercentages } from '../../services/IncomesService'
import { toast } from 'react-toastify'
import { decrypt } from '../../utils/crypto'

export default function Incomes() {
  const rule = decrypt(sessionStorage.getItem('rule'))
  const [percentageIncomes, setPercentageIncomes] = useState([])
  const [show, setShow] = useState(false)

  const doGetPercentageIncomes = async () => {
    const success = await getPercentageIncomes()
    if (success.status) {
      setPercentageIncomes(success.percentages)
      setShow(true)
    }
  }

  const doSave = async () => {
    setShow(false)
    const success = await savePercentages(percentageIncomes)
    if (success.status) {
      doGetPercentageIncomes()
      toast.success(i18n.t('ok_perc_change'))
    }
  }

  useEffect(() => {
    doGetPercentageIncomes()
  }, [])

  return (
    <React.Fragment>
      <Menu />
      {['developer', 'finance'].includes(rule) && (
        <main className="content">
          <>
            {!show ? (
              <div className="alert alert-warning">{i18n.t('waiting')}</div>
            ) : (
              <div className="container-fluid">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                  <div className="d-block mb-4 mb-md-0">
                    <h2 className="h4">{i18n.t('incomes')}</h2>
                  </div>
                </div>
                <div className="card card-body border-0 shadow mb-5">
                  <h4>{i18n.t('title_incomes')}</h4>
                  {percentageIncomes.map((p, i) => (
                    <div className="row mt-3 text-center">
                      <div className="bg-primary text-light rounded col-md d-flex justify-content-center align-items-center">
                        {parseFloat(p.plan) === 0 ? i18n.t('dayly') : `${p.plan} ${i18n.t('days')}`}
                      </div>
                      <div className="form-group col-md">
                        <div className="bg-dark rounded w-100 p-2">{i18n.t('sunday')}</div>
                        <input
                          type="number"
                          min={0}
                          max={100}
                          step={0.1}
                          value={p.d0}
                          className="form-control"
                          onChange={e =>
                            setPercentageIncomes(prev =>
                              prev.map(np => (p.id === np.id ? { ...np, d0: parseFloat(e.target.value) } : np))
                            )
                          }
                        />
                      </div>
                      <div className="form-group col-md">
                        <div className="bg-dark rounded w-100 p-2">{i18n.t('monday')}</div>
                        <input
                          type="number"
                          min={0}
                          max={100}
                          step={0.1}
                          value={p.d1}
                          className="form-control"
                          onChange={e =>
                            setPercentageIncomes(prev =>
                              prev.map(np => (p.id === np.id ? { ...np, d1: parseFloat(e.target.value) } : np))
                            )
                          }
                        />
                      </div>
                      <div className="form-group col-md">
                        <div className="bg-dark rounded w-100 p-2">{i18n.t('tuesday')}</div>
                        <input
                          type="number"
                          min={0}
                          max={100}
                          step={0.1}
                          value={p.d2}
                          className="form-control"
                          onChange={e =>
                            setPercentageIncomes(prev =>
                              prev.map(np => (p.id === np.id ? { ...np, d2: parseFloat(e.target.value) } : np))
                            )
                          }
                        />
                      </div>
                      <div className="form-group col-md">
                        <div className="bg-dark rounded w-100 p-2">{i18n.t('wednesday')}</div>
                        <input
                          type="number"
                          min={0}
                          max={100}
                          step={0.1}
                          value={p.d3}
                          className="form-control"
                          onChange={e =>
                            setPercentageIncomes(prev =>
                              prev.map(np => (p.id === np.id ? { ...np, d3: parseFloat(e.target.value) } : np))
                            )
                          }
                        />
                      </div>
                      <div className="form-group col-md">
                        <div className="bg-dark rounded w-100 p-2">{i18n.t('thursday')}</div>
                        <input
                          type="number"
                          min={0}
                          max={100}
                          step={0.1}
                          value={p.d4}
                          className="form-control"
                          onChange={e =>
                            setPercentageIncomes(prev =>
                              prev.map(np => (p.id === np.id ? { ...np, d4: parseFloat(e.target.value) } : np))
                            )
                          }
                        />
                      </div>
                      <div className="form-group col-md">
                        <div className="bg-dark rounded w-100 p-2">{i18n.t('friday')}</div>
                        <input
                          type="number"
                          min={0}
                          max={100}
                          step={0.1}
                          value={p.d5}
                          className="form-control"
                          onChange={e =>
                            setPercentageIncomes(prev =>
                              prev.map(np => (p.id === np.id ? { ...np, d5: parseFloat(e.target.value) } : np))
                            )
                          }
                        />
                      </div>
                      <div className="form-group col-md">
                        <div className="bg-dark rounded w-100 p-2">{i18n.t('saturday')}</div>
                        <input
                          type="number"
                          min={0}
                          max={100}
                          step={0.1}
                          value={p.d6}
                          className="form-control"
                          onChange={e =>
                            setPercentageIncomes(prev =>
                              prev.map(np => (p.id === np.id ? { ...np, d6: parseFloat(e.target.value) } : np))
                            )
                          }
                        />
                      </div>
                      {/* <pre>{JSON.stringify(percentageIncomes, null, 2)}</pre> */}
                    </div>
                  ))}
                  <button className="btn btn-success btn-lg mt-3 mx-auto" onClick={doSave}>
                    <i className="fa fa-check"></i> {i18n.t('save')}
                  </button>
                  {/* <pre>{JSON.stringify(percentageIncomes, null, 2)}</pre> */}
                </div>
              </div>
            )}
          </>
        </main>
      )}
    </React.Fragment>
  )
}
