import React, { useState, useEffect } from 'react'
import Menu from '../../components/Menu/Menu'
import { getDashboard } from '../../services/HydraService'
import InfoBlock from './InfoBlock/InfoBlock'
import { i18n } from '../../utils/translates/i18n'
import { FormateFiatCurrency } from '../../utils/CustomFormatations'
import LoaderPage from '../../utils/LoaderPage'
import { toast } from 'react-toastify'
import Balances from './dashboardComponents/Balances'
import { decrypt } from '../../utils/crypto'

function Dashboard() {
  const [report, setReport] = useState({})
  const [show, setShow] = useState(false)
  const rule = decrypt(sessionStorage.getItem('rule'))

  useEffect(() => {
    getDashboard()
      .then(result => {
        setReport(result)
        setShow(true)
      })
      .catch(error => {
        console.error(error.response ? error.response.data : error)
        toast.error(error.response ? error.response.data : error)
        setShow(true)
      })
  }, [])

  const t = sessionStorage.getItem('t') === 'true'

  return (
    <React.Fragment>
      <Menu />
      <main className="content">
        {!show ? (
          <LoaderPage />
        ) : (
          <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <h2 className="h4">Dashboard</h2>
              </div>
            </div>
            <div className="row">
              {!t && (
                <>
                  <InfoBlock
                    title={i18n.t('today_entry')}
                    value={
                      ['manager'].includes(rule)
                        ? FormateFiatCurrency((parseFloat(report.paids) / 100) * 50)
                        : FormateFiatCurrency(report.paids)
                    }
                    precision={0}
                    background="warning"
                    // subs={[
                    //   {
                    //     title: 'system',
                    //     value: ['manager'].includes(rule)
                    //       ? FormateFiatCurrency((parseFloat(report.paidsSystem) / 100) * 50)
                    //       : FormateFiatCurrency(report.paidsSystem),
                    //   },
                    //   {
                    //     title: 'balance',
                    //     value: ['manager'].includes(rule)
                    //       ? FormateFiatCurrency((parseFloat(report.paidsBalance) / 100) * 50)
                    //       : FormateFiatCurrency(report.paidsBalance),
                    //   },
                    //   {
                    //     title: 'admin',
                    //     value: ['manager'].includes(rule)
                    //       ? FormateFiatCurrency((parseFloat(report.paidsAdmin) / 100) * 50)
                    //       : FormateFiatCurrency(report.paidsAdmin),
                    //   },
                    //   {
                    //     title: 'voucher',
                    //     value: ['manager'].includes(rule)
                    //       ? FormateFiatCurrency((parseFloat(report.paidsVoucher) / 100) * 50)
                    //       : FormateFiatCurrency(report.paidsVoucher),
                    //   },
                    //   {
                    //     title: 'others',
                    //     value: ['manager'].includes(rule)
                    //       ? FormateFiatCurrency((parseFloat(report.paidsOthers) / 100) * 50)
                    //       : FormateFiatCurrency(report.paidsOthers),
                    //   },
                    // 	]}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </InfoBlock>
                  <InfoBlock
                    title={i18n.t('total_entry')}
                    value={FormateFiatCurrency((parseFloat(report.paidsTotal) / 100) * 50)}
                    precision={0}
                    background="success"
                    // subs={[
                    //   { title: 'system', value: FormateFiatCurrency(report.paidsSystemTotal) },
                    //   { title: 'balance', value: FormateFiatCurrency(report.paidsBalanceTotal) },
                    //   { title: 'admin', value: FormateFiatCurrency(report.paidsAdminTotal) },
                    //   { title: 'voucher', value: FormateFiatCurrency(report.paidsVoucherTotal) },
                    //   { title: 'others', value: FormateFiatCurrency(report.paidsOthersTotal) },
                    // 	]}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </InfoBlock>
                  <InfoBlock
                    title={i18n.t('balance_network')}
                    value={FormateFiatCurrency(report.networkBalance)}
                    precision={0}
                    background="primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </InfoBlock>
                  <InfoBlock
                    title={`${i18n.t('withdrawals')} ${i18n.t('waiting')}`}
                    value={
                      ['manager'].includes(rule)
                        ? FormateFiatCurrency((parseFloat(report.withdrawals) / 100) * 50)
                        : FormateFiatCurrency(report.withdrawals)
                    }
                    precision={0}
                    background="danger">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </InfoBlock>
                  <Balances rule={rule} />
                  {/* <PoolFastMatrix /> */}
                </>
              )}
              <InfoBlock title={i18n.t('active_users')} value={report.users} precision={0} background="primary">
                <svg className="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                </svg>
              </InfoBlock>
            </div>
          </>
        )}
      </main>
      {/* <Footer /> */}
    </React.Fragment>
  )
}

export default Dashboard
