import axios from './BaseService'

const WITHDRAWALS_URL = `${process.env.REACT_APP_API_URL}/withdraws/`

export async function getWithdrawals(token, page, limit, search, paymentMethod) {
  const endPoint = `${WITHDRAWALS_URL}list${page ? `?page=${page}` : ''}`
  const response = await axios.post(endPoint, { limit, search, paymentMethod })
  return response.data
}

export async function getWithdrawalsFiles(token, page, limit) {
  const endPoint = `${WITHDRAWALS_URL}files${page ? `?page=${page}` : ''}`
  const response = await axios.post(endPoint, { limit })
  return response.data
}

export async function payWithdraw(token, withdrawals, coin) {
  const endPoint = `${WITHDRAWALS_URL}payWithdraw`
  const response = await axios.post(endPoint, { withdrawals, coin })
  return response.data
}

export async function markAsPaid(token, withdrawals, coin) {
  const endPoint = `${WITHDRAWALS_URL}markaspaid`
  const response = await axios.post(endPoint, { withdrawals, coin })
  return response.data
}

export async function generateFileWithdraw(token, withdrawals, coin) {
  const endPoint = `${WITHDRAWALS_URL}generatefilewithdraw`
  const response = await axios.post(endPoint, { withdrawals, coin })
  return response.data
}

export async function doChargeback(token, id) {
  const endPoint = `${WITHDRAWALS_URL}chargeback/${id}`
  const response = await axios.get(endPoint)
  return response.data
}
