import axios from './BaseService'

const CALLS_URL = `${process.env.REACT_APP_API_URL}/calls/`

export async function getCalls(token, page, limit, search) {
  const endPoint = `${CALLS_URL}list${page ? `?page=${page}` : ''}`

  const response = await axios.post(endPoint, { limit, search })
  return response.data
}

export const getCallMessages = async (callId, token) => {
  const endPoint = `${CALLS_URL}call/${callId}`
  const response = await axios.get(endPoint)
  return response.data
}

export const sendCallMessages = async (data, token) => {
  const endPoint = `${CALLS_URL}reply`
  const response = await axios.post(endPoint, data)
  return response.data
}
